<template>
  <div>

     <div class="footerBar">
       <div class="rightBtn">
         <el-button type="info" size="medium" @click="cancalFun" plain>取消</el-button>
         <el-button type="primary" size="medium" @click="submitFun('goodArr')">确认提交</el-button>
       </div>
     </div>
     <div class="contents">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:10px 10px 15px">
    <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>机器人</el-breadcrumb-item>
    <el-breadcrumb-item>供应商群管理</el-breadcrumb-item>
  </el-breadcrumb>
       <div class="mainbox">
  <div class="formTable">
        <el-form ref="goodArr" :model="goodArr" :rules="rules" label-position="right" label-width="300px">
          <el-form-item label="选择机器人：" prop="botId" style="margin-bottom:10px">
            <el-select v-model="goodArr.botId" :disabled="boname?true:false" placeholder="请选择" @change="changeAdmin">
              <el-option
                v-for="item in groupCode"
                :key="item.id"
                :label="item.name"
                :value="item.botId">
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="群名称：" prop="groupId" style="margin-bottom:10px">
            <el-select v-model="goodArr.groupId" filterable :disabled="boname?true:false" clearable placeholder="请选择" @change="qunClicks">
              <el-option
                v-for="item in Groupoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
             <el-button type="text" size="medium" style="margin-left:10px" @click="getGroupMember" plain>找不到群？试试在群里说说话</el-button>
          </el-form-item>

          <el-form-item label="供应商名称：" prop="name" style="margin-bottom:10px;width:600px;">
            <el-input v-model="goodArr.name" placeholder="请输入供应商名称"></el-input>
            <label style="color:red;font-size:12px;float:left;">如遇名称重复提示，请修改此名称</label>
          </el-form-item>
        
          <el-form-item label="选择收单人：" style="margin-bottom:10px" prop="worker">
            <el-select v-model="goodArr.worker" clearable placeholder="请选择" @change="activeClicks">
              <el-option
                v-for="item in memberoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <label class="tips">群消息会@此人，通知他接收订单并且尽快发货</label>
          </el-form-item>

          <el-form-item label="对方财务负责人：" prop="channelOwner" style="margin-bottom:10px">
            <el-select v-model="goodArr.channelOwner" filterable clearable placeholder="请选择" @change="ownerClicks">
              <el-option
                v-for="item in memberoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <label class="tips">账单会@此人收款</label>
          </el-form-item>

          <el-form-item label="是否启用机器人：">
            <el-radio-group v-model="goodArr.valid">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="是否自动交单：">
            <el-radio-group v-model="goodArr.autoSend">
             <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
            <br/>
             <label class="tips" >开启自动交单，系统会在设置的交单时间自动将订单Excel发送到此群</label>
          </el-form-item>
         

          <el-form-item label="自动交单时间：" v-if="goodArr.autoSend==1">
            <div class="timebox">
            <div class="additem" @click="addfun">+</div>
            <div v-for="(item, index) in deadlines" :key="index" style="margin-bottom:10px"> 
            <el-time-picker
                v-model="item.value"
                placeholder="任意时间点"
                format="HH:mm"
              >
            </el-time-picker>
            <div class="delitem" v-if="index>0" @click="delfun(index)">×</div>
            </div>
            </div>
            <label class="tips">开启自动交单，已匹配供应商的订单，会在设置的交单时间自动推送订单Excel到供应商群</label>
          </el-form-item>

          <el-form-item label="交单模板：" style="margin-bottom:10px">
            <el-select v-model="goodArr.sendTemplate" filterable clearable placeholder="请选择" @change="changeTemplate">
              <el-option
                v-for="item in templates"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-button type="text" size="small" @click="clickFun" plain>&nbsp;创建新模板</el-button>
          </el-form-item>

          <el-form-item label="订单文件交单形式：">
            <el-radio-group v-model="goodArr.splitBy">
             <el-radio :label="0">全部交单</el-radio>
              <el-radio :label="1">按商品拆分表格</el-radio>
             <el-radio :label="2">按SKU拆分表格</el-radio>
              <el-radio :label="3">按团购标题/活动名称分表格</el-radio>
            </el-radio-group>
          </el-form-item>          

          <el-form-item label="是否自动接收物流返单：">
            <el-radio-group v-model="goodArr.autoReceive">
             <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="禁收物流返单消息的群成员列表：" style="margin-bottom:10px">
            <el-select v-model="goodArr.disableMembers" filterable clearable multiple placeholder="请选择" @change="contactClicks">
              <el-option
                v-for="item in memberoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="不接收物流单文件名关键字：" style="margin-bottom:10px;width:600px;">
            <el-input v-model="goodArr.disableFileNames" placeholder="请输入不接收文件名关键字"></el-input> <span class="tips">(* 关键词之间用逗号","隔开)</span>
          </el-form-item>
          
           <el-form-item label="备注：" style="margin-bottom:10px;width:600px;">
            <el-input type="textarea" :rows="3" v-model="goodArr.remark" placeholder="备注"></el-input>
          </el-form-item>

        </el-form>
       </div>
       </div>   
    </div>
  </div>
</template>

<script>
import { configSupplierGroup, getBotList,contactFun,groupFun, groupMember, supplierGroupDetail } from "@/api/robot";
import { listMoban } from "@/api/moban.js"

let moment = require('moment');
export default {
  name: 'ChannelMain',
  data() {
    return {
      goodArr: {
        id: '',
        name:'',
        botId: '',
        robotId: '',
        autoReturn: 1,
        autoReceive: 1,
        splitBy: 0,
        channelOwner: null,
        groupId: '',
        groupName: '',
        multiExpressLines: 0,
        autoReceiptWhenReceive: 1,
        autoReceiptWhenReturn: 1,
        remark: '',
        autoSend: 0,
        sendTemplate: null,
        autoReturnRules: 0,
        ownerObj: null,
        contactObj: null,
        activeObj: null,
        worker: null,
        disableFileNames: "异常,账单",
        disableMembers: null,
        valid: 1,
      },
      deadlines: [{value:""}],
      options: [],
      Groupoptions: [],
      boname: '',
      qid: '',
      checked: false,
      classflag: false,
      showgood: false,
      loading: false,
      groupCode: [],
      tableData: [],
      goodsData: [],
      memberoptions: [],
      page: 1,
      pageSize: 10,
      total:0,
      multipleSelection: [],
      rules: {
        groupId: [{ required: true, message: "请选择群名称", trigger: "change" }],
        name: [{ required: true, message: "请输入供应商名称", trigger: "change" }],
        botId: [{ required: true, message: "请选择机器人", trigger: "change" }],
        channelOwner: [{ required: false, message: "请选择负责人", trigger: "change" }],
        worker: [{ required: true, message: "请选择订单接收人", trigger: "change" }],
      },
      templates: []
    }
  },
  created: function () {
   
  },
  methods:{
    listTemplate() {
      const param = {
        role: window.sessionStorage.getItem("role")
      }
      listMoban(param).then(res => {
        if(res.code ===200) {
          this.templates = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    changeTemplate(value) {
      this.goodArr.sendTemplate = value
    },
    addfun(){
      var that = this
      var objs= {
        value: ''
      }
      that.deadlines.unshift(objs)
    },
    delfun(n){
      var that = this
      that.deadlines.splice(n,1)
    },
    handleClose(){
      this.showgood = false;
    },
    getBodList(){
      var that = this
      var datas = {}
      datas.pageNo = 1;
      datas.pageSize = 100;
      datas.orderByCreated = "DESC";
      getBotList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.groupCode = res.data.rows
          if(that.boname){
            that.groupCode.map((item)=>{
                if(item.botId == that.boname){
                  that.goodArr.robotId = item.id
                  return
                }
            })
          }
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changeAdmin(e){
      console.log(e)
      var that = this
      that.groupCode.map((item)=>{
        if(item.botId == e){
          that.goodArr.robotId = item.id
        }
      })
      console.log(that.goodArr.robotId)
      that.getGroupList(e)
    },
    getAdminList(botId){
      var that = this
      var datas = {
        botId: botId
      }
      contactFun(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.options = res.data.contacts
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getGroupList(botId){
      var that = this
      var datas = {
        botId: botId
      }
      groupFun(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.Groupoptions = res.data.groups
          that.Groupoptions.map((item)=>{
            if(item.id==that.goodArr.groupId){
              that.goodArr.groupName = item.name
              return
            }
          })
          if(that.qid){
            that.Groupoptions.map((item)=>{
              if(item.id==that.qid){
                that.goodArr.groupName = item.name
                that.goodArr.name = item.name
                return
              }
            })
          }
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getGroupMember(){
      var that = this
      if(!that.goodArr.botId || !that.goodArr.groupId){
        that.$message.error('请先选择群组和所绑定的机器人')
        return false
      }
      var datas = {
        botId: that.goodArr.botId,
        groupId: that.goodArr.groupId
      }
      groupMember(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.memberoptions = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getGoodList()
    },
    clickFun(){
      var that = this
      that.$router.push({
        path: "/sendmoban"
      })
    },
    getDetail(ids){
      var that = this
      var objs = {}
      objs.id = ids
      supplierGroupDetail(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.goodArr.date = []
          that.goodArr.id = res.data.id
          that.goodArr.name = res.data.name
          that.goodArr.remark = res.data.remark
          that.goodArr.valid = res.data.valid
          that.goodArr.groupId = res.data.groupId
          that.goodArr.groupName = res.data.groupName
          that.goodArr.botId = res.data.botId
          that.goodArr.robotId = res.data.robotId
          that.goodArr.sendTemplate = res.data.sendTemplate
          that.goodArr.autoSend = res.data.autoSend
          that.goodArr.disableFileNames = res.data.disableFileNames ? res.data.disableFileNames.join(",") : ""
          that.goodArr.autoReceive = res.data.autoReceive
          that.goodArr.splitBy = res.data.splitBy
          that.goodArr.channelOwner = res.data.channelOwner ? res.data.channelOwner.id : null
          that.goodArr.ownerObj = res.data.channelOwner

          that.goodArr.worker = res.data.worker ? res.data.worker.id : null
          that.goodArr.activeObj = res.data.worker

          res.data.deadlines.map((iy)=>{
               var objs={}
               objs.value = new Date('1970-01-01 ' + iy)
               that.deadlines.push(objs)
          })

          var disableMemberArr = res.data.disableMembers || []
          var disableIdArr = []
          disableMemberArr.map((item)=>{
            disableIdArr.push(item.id)
          })
          that.goodArr.disableMembers = disableIdArr

          that.getGroupList(res.data.botId)
          that.getGroupMember()

        }else{
          this.$message.error(res.message)
        }
      })
    },
    cancalFun(){
      this.$router.go(-1)
    },
    
    addClass(){
      this.classflag = true
    },
    ownerClicks(val){
      var that = this
      that.memberoptions.map((item)=>{
        if(item.id == val){
          that.goodArr.ownerObj = item
        }
      })
    },
    activeClicks(val){
      var that = this
      that.memberoptions.map((item)=>{
        if(item.id == val){
          that.goodArr.activeObj = item
        }
      })
    },
    contactClicks(val){
      var that = this
      console.log(val)
    },
    qunClicks(e){
      var that = this
      that.Groupoptions.map((item)=>{
        if(item.id == e){
          that.goodArr.groupId = e
          that.goodArr.groupName = item.name
          that.goodArr.name = item.name
        }
      })
      that.getGroupMember(e)
    },
    submitFun(){
      var that = this
      var params = {}
      if(!that.goodArr.name) { that.$message.error('请输入供应商名称'); return false }
      console.log( that.goodArr)
      params.robotId = that.goodArr.robotId
      params.autoSend = that.goodArr.autoSend
      params.valid = that.goodArr.valid
      params.autoReceive = that.goodArr.autoReceive
      params.splitBy = that.goodArr.splitBy
      params.name = that.goodArr.name
      params.channelOwner = that.goodArr.ownerObj
      params.groupId = that.goodArr.groupId
      params.groupName = that.goodArr.groupName
      params.remark = that.goodArr.remark
      params.worker = that.goodArr.activeObj
      params.sendTemplate = that.goodArr.sendTemplate
      params.deadlines = []      
      that.deadlines.map((items)=>{
        if(items.value != "") {
          items.value = moment(items.value).format('HH:mm')
          params.deadlines.push(items.value)
        }
      })
      params.disableFileNames = that.goodArr.disableFileNames.replace("，",",").split(",")
      var disableMembersArr = []
      if(that.goodArr.disableMembers.length > 0){
        that.memberoptions.map((item)=>{
          that.goodArr.disableMembers.map((its)=>{
            if(item.id == its){
              disableMembersArr.push(item)
            }
          }) 
        })
      }
      params.disableMembers = disableMembersArr

      console.log(params)
      that.loading = true
      if(that.goodArr.id){
        params.id = that.goodArr.id
        configSupplierGroup(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '编辑成功',
              type: 'success'
            })
            setTimeout(() =>{
              that.$router.push({
                path: "/suppliergroup"
              })
            }, 300)
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }else{
        configSupplierGroup(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '创建成功',
              type: 'success'
            })
            setTimeout(() =>{
              that.$router.push({
                path: "/suppliergroup"
              })
            }, 300)
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }
    
    },
  },
  mounted() {
    var goodid = this.$route.query.id
    var qunid = decodeURI(this.$route.query.qid)
    var boname = this.$route.query.name
    console.log(goodid)
    if(goodid){
      this.getDetail(goodid)
    }
    this.getBodList()
    if(boname && qunid){
      this.boname = boname
      this.qid = qunid
      this.goodArr.botId = boname
      this.goodArr.groupId = qunid
      this.getGroupList(boname)
      this.getGroupMember()
    }
    this.listTemplate()
  }
}
</script>

<style scoped>
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.tips{
  font-size: 12px;
  color: #999;
  margin-left:10px;
}
.timebox{
  width: 300px;
  display: block;
}
.additem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #07C160;
  margin-left: 10px;
  cursor: pointer;
}
.delitem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #999;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #f3f3f3;
  margin-left: 10px;
  cursor: pointer;
}
</style>